export default new (function () {

    var ifr_c = 0;

    this.mfdrequest = function (url, form, sf, ef) {

        if (form.length != 1 || !form.is('form')) {
            return;
        }

        var ifr_id = 'mfdrequest_ifr_' + (++ifr_c);
        var ifr = $('<iframe>');

        ifr.css({ position: 'absolute', top: '-99px', left: '-99px', width: 3, height: 4 });

        ifr.attr('name', ifr_id);
        ifr.data('sf', sf);
        ifr.data('ef', ef);

        var csrft = form.find('[name="xcsrf-token"]');
        if (!csrft.length) {
            csrft = $('<input type="hidden" name="xcsrf-token"/>');
            form.append(csrft);
        }
        csrft.val($.trim($('meta[name="xcsrf-token"]').attr('content')));

        $('body').append(ifr);

        ifr.on('load', function () {
            var _t = $(this);

            var ans = _t.contents().text();

            var _sf = _t.data('sf');
            var _ef = _t.data('ef');

            var d = null;

            if (/^[\{].*[\}]$/.test(ans)) {
                try {
                    if (JSON && JSON.parse) {
                        d = JSON.parse(ans);
                    } else {
                        d = $.secureEvalJSON(ans);
                    }
                } catch (e) {
                    console.log(e.message);
                }
            }

            if (d) {
                if (d.redirect) {
                    var path = window.location.pathname;
                    path = decodeURIComponent(path.replace('/', ''));
                    path += window.location.search;
                    document.cookie = 's7_referer=' + path;
                    window.location = d.redirect;
                    return false;
                }

                if (_sf && _sf.call) {
                    _sf(d);
                }
            } else {
                if (_ef && _ef.call) {
                    _ef(ans);
                }
            }

            _t.data('sf', null);
            _t.data('ef', null);

            _t.off();
            _t.remove();
        });

        form.attr('enctype', 'multipart/form-data');
        form.attr('method', 'post');
        form.attr('action', url);
        form.attr('target', ifr_id);

        form.submit();
    }


    var dd = function (v) {
        v = parseInt(v);
        return v > 9 ? v : '0' + v;
    }

    this.time = function (t) {
        var d = t ? new Date(t * 1e3) : new Date();
        return dd(d.getHours()) + ':' +
            dd(d.getMinutes()) + ':' +
            dd(d.getSeconds());
    }

    this.date = function (t) {
        var d = t ? new Date(t * 1e3) : new Date();
        return this.fdate(d);
    }

    this.month = function (t) {
        var d = t ? new Date(t * 1e3) : new Date();
        var d1 = new Date(d.getFullYear(), d.getMonth(), 1);
        var d2 = new Date(d.getFullYear(), d.getMonth() + 1, 1);
        return [this.fdate(d1), this.fdate(d2)];
    }

    this.pmonth = function (d) {
        var _m = d.getMonth();
        var _y = d.getFullYear();
        return new Date(_m > 0 ? _y : (_y - 1), _m > 0 ? (_m - 1) : 11, 1);
    }

    this.prvMonthStr = function (s) {
        var _s = s.trim().split('.');
        if (_s.length < 3) return '';
        var _m = Math.abs(parseInt(_s[1]) - 1);
        var _y = parseInt(_s[2]);
        return this.fdate(new Date(_m > 0 ? _y : (_y - 1), _m > 0 ? (_m - 1) : 11, 1));
    }

    this.nxtMonthStr = function (s) {
        var _s = s.trim().split('.');
        if (_s.length < 3) return '';
        var _m = Math.abs(parseInt(_s[1]) - 1);
        var _y = parseInt(_s[2]);
        return this.fdate(new Date(_m < 11 ? _y : (_y + 1), _m < 11 ? (_m + 1) : 0, 1));
    }

    this.cmonth = function (d) {
        if (!d) d = new Date();
        return new Date(d.getFullYear(), d.getMonth(), 1);
    }

    this.fdate = function (d) {
        return dd(d.getDate()) + '.' + dd(d.getMonth() + 1) + '.' + d.getFullYear();
    }

    this.fdatets = function (t) {
        return this.fdate(new Date(t * 1000));
    }

    this.curdate = function () {
        var d = new Date();
        return dd(d.getDate()) + '.' + dd(d.getMonth() + 1) + '.' + d.getFullYear();
    }

    this.prevdate = function () {
        return this.fdate(this.pmonth(new Date()));
    }

    this.request = function (url, data, sf, ef) {
        var params = {};
        params.url = url;
        params.dataType = 'json';
        params.method = 'post';
        params.contentType = "application/x-www-form-urlencoded";
        params.data = data;
        params.error = function (xhr, status, msg) {
            if (ef && ef.call) {
                ef(msg);
            }
        };
        params.headers = { 'xcsrf-token': $.trim($('meta[name="xcsrf-token"]').attr('content')) };
        //
        params.success = function (d, msg) {
            if (d.redirect) {
                var path = window.location.pathname;
                path = decodeURIComponent(path.replace('/', ''));
                path += window.location.search;
                document.cookie = 's7_referer=' + path;
                window.location = d.redirect;
                return false;
            }

            if (sf && sf.call) {
                sf(d, msg);
            }
        };
        $.ajax(params.url, params);
    }

})();