'use strict';

import Api from './lib/util';

let app = angular.module('seller7App', ['ngCookies']);

app.controller('AuthorizationController', ['$scope', '$http', '$cookies', '$timeout', ($scope, $http, $cookies, $timeout) => {

	$scope.login = '';
    $scope.password = '';
    
    $scope.showpswd = false;

	$scope.errors = { login: '', password: '' };

    $scope.pending = false;
    
    $scope.showPasswd = () => {
        $scope.showpswd = true;
    }

    $scope.hidePasswd = () => {
        $scope.showpswd = false;
    }

	$scope.autorization = ($event) => {

		if ($event && ($scope.errors.login || $scope.errors.password)) {
			$scope.errors.login = '';
			$scope.errors.password = '';
		}

		if ($event && $event.charCode != 13 && $event.keyCode != 13) return true;
		if ($scope.pending) return true;

		$scope.errors.login = '';
		$scope.errors.password = '';

		$scope.pending = true;

		var data = {
			login: $scope.login,
			password: $scope.password
		};

		Api.request('/login',
			data,
			(d) => {
				if (d.success) {
					window.location = '/';
				} else {
					$scope.errors.login = d.errors.login || d.error || '';
					$scope.errors.password = d.errors.password || '';
				}
				$scope.pending = false;
				$timeout();
			},
			() => {
				$scope.pending = false;
				$timeout();
			}
		);

		return false;
	};

}]);

angular.bootstrap(document, [app.name]);